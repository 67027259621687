import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";

function Container({ children, className }) {
  const cx = classNames.bind(styles, className);

  return (
    <div
      className={cx({
        container: true,
        className: true,
      })}
    >
      {children}
    </div>
  );
}

export default Container;
