import {createStore, applyMiddleware, combineReducers} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import app from './app';

const reducers = combineReducers({
  app,
});

const initialState = {};

const store = createStore(reducers, initialState, composeWithDevTools(applyMiddleware(thunk)));

export default store;
