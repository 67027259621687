import React, { useRef } from "react";
import { Select, DatePicker, Checkbox, Modal } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import "moment/locale/ru";
import locale from "antd/es/date-picker/locale/ru_RU";
import { ReactComponent as UserIcon } from "./user-icon.svg";
import styles from "./styles.module.scss";
import { getNextFreeTime, checkIsValidDate } from "../services/form-helper";
import InputMask from "react-input-mask";
import "yup-phone";
import Recaptcha from "react-google-invisible-recaptcha";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Api from "../../services/api";
import { actions } from "../../store/app";
import { Spinner } from "react-activity";
import "react-activity/dist/react-activity.css";

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

// site - 6LcoxSEaAAAAAIgMbZjb0NIeQCIFIR7zvb3nVBzV
// secret - 6LcoxSEaAAAAAONaLkDAfDNMxXay5qUjKU9eeTHQ

const Form = () => {
  const { schedule, takenDates, prices, documents } = useSelector(
    (store) => store.app,
    shallowEqual
  );

  const dispatch = useDispatch();

  const captchaRef = useRef(null);

  const initialValues = {
    name: "",
    phone: "",
    date: getNextFreeTime(null, takenDates, schedule),
    category: "B",
    acceptTerms: false,
    reCaptcha: null,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Обязательное поле")
      .min(5, "Не менее пять символов")
      .max(50, "Не более 50 символов"),
    phone: Yup.string()
      .required("Обязательное поле")
      .phone("RU", true, "Неверный номер телефона"),
    date: Yup.object().test(
      "is-valid-date",
      "Недоступное для записи время",
      function (value) {
        return checkIsValidDate(value, takenDates);
      }
    ),
    acceptTerms: Yup.bool().oneOf([true]),
  });

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf("hour");
  };

  const disabledDateTime = (current) => {
    const dayOfWeek = current.day();
    const workingHours = schedule[dayOfWeek].map((time) =>
      time.split(":").map((value) => parseInt(value, 10))
    );

    const hours = [
      ...range(0, workingHours[0][0]),
      ...range(workingHours[1][0], 24),
    ];

    const disabledMinutes = [];

    takenDates
      .map((date) => moment(date))
      .filter((date) => date.isSame(current, "hour"))
      .forEach((date) => disabledMinutes.push(date.minute()));

    return {
      disabledHours: () => hours,
      disabledMinutes: () => disabledMinutes,
    };
  };

  const onCaptchaResolved = (formik) => {
    formik.setFieldValue("reCaptcha", captchaRef.current.getResponse());
    formik.handleSubmit();
  };

  const onPolicyClick = () => {
    window.open(documents.policy, "_blank");
  }

  const submit = async (values, formik) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("phone", "'" + values.phone);
    formData.append("category", values.category);
    formData.append("date", values.date.format("Y-MM-DD H:m"));
    formData.append("captcha", values.reCaptcha);

    await Api("?action=add", formData, "post", {
      "Content-Type": "multipart/form-data",
    });

    dispatch(
      actions.update({
        takenDates: [...takenDates, values.date.format("Y-MM-DD H:m:00")],
      })
    );

    Modal.success({
      title: "Запись на техосмотр",
      icon: null,
      content: `Вы были записаны на техосмотр автомобиля ${values.date.format(
        "DD MMMM Y в HH:mm"
      )}.`,
      okText: "OK",
      cancelText: null,
    });

    // TODO domain-based content
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={submit}
    >
      {(formik) => (
        <div>
          <div className={styles.title}>Запись на техосмотр</div>

          <div className={styles.field}>
            <input
              type="text"
              placeholder={"ФИО"}
              onChange={formik.handleChange("name")}
              value={formik.values.name}
            />
            <UserIcon />
          </div>

          {formik.errors?.name && (
            <div className={styles.errorMessage}>{formik.errors?.name}</div>
          )}

          <div className={styles.field}>
            <InputMask
              mask="+7 (999) 999-99-99"
              onChange={formik.handleChange("phone")}
              value={formik.values.phone}
              placeholder={"Номер телефона"}
            />

            <UserIcon />
          </div>

          {formik.errors?.phone && (
            <div className={styles.errorMessage}>{formik.errors?.phone}</div>
          )}

          <div className={styles.field}>
            <DatePicker
              value={formik.values.date}
              locale={locale}
              format="DD MMMM Y HH:mm"
              disabledDate={disabledDate}
              disabledTime={disabledDateTime}
              onChange={(value) => formik.setFieldValue("date", value)}
              allowClear={false}
              showTime={{
                defaultValue: moment("08:00:00", "HH:mm"),
                format: "HH:mm",
                minuteStep: 30,
              }}
            />
          </div>

          {formik.errors?.date && (
            <div className={styles.errorMessage}>{formik.errors?.date}</div>
          )}

          <div className={styles.field}>
            <Select
              defaultValue="B"
              style={{ width: "100%" }}
              onChange={formik.handleChange("category")}
            >
              {!!prices.A && (
                  <Select.Option value="A">Мотоцикл</Select.Option>
              )}
              {!!prices.B && (
                <Select.Option value="B">Легковой автомобиль</Select.Option>
              )}
              {!!prices.C && (
                <Select.Option value="C">Грузовой автомобиль</Select.Option>
              )}
              {!!prices.D && <Select.Option value="D">Автобус</Select.Option>}
              {!!prices.O && <Select.Option value="O">Прицеп</Select.Option>}
            </Select>
          </div>

          {formik.errors?.category && (
            <div className={styles.errorMessage}>{formik.errors?.category}</div>
          )}

          <Checkbox
            className={styles.checkbox}
            checked={formik.values.acceptTerms}
            onChange={formik.handleChange("acceptTerms")}
          >
            Согласен с <a onClick={onPolicyClick}>политикой обработки данных</a>
          </Checkbox>

          <Recaptcha
            ref={captchaRef}
            sitekey="6Lcf1ygaAAAAAPBKx34zQVtiW3dTGylZLfUmMIGO"
            onResolved={() => onCaptchaResolved(formik)}
          />

          <button
            className={styles.submit}
            onClick={() => captchaRef.current.execute()}
          >
            {formik.isSubmitting && <Spinner color={"white"} />}
            {!formik.isSubmitting && "Отправить заявку"}
          </button>
        </div>
      )}
    </Formik>
  );
};

export default Form;
