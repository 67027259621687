import React, { useEffect, useState, useCallback } from "react";
import Header from "./components/header/Header";
import Hero from "./components/hero/Hero";
import Documents from "./components/documents/Documents";
import Footer from "./components/footer/Footer";
import Api from "./services/api";
import { useDispatch } from "react-redux";
import { actions } from "./store/app";
import Logo from "./components/header/logo.svg";

const App = () => {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  const init = useCallback(async () => {
    const { data } = await Api("?action=settings");
    const { data: takenDates } = await Api("?action=taken-dates");
    await dispatch(actions.update({ ...data, takenDates }));

    document.title = data.requisites.shortName;

    setLoaded(true);
  }, [dispatch]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <div className={"preloader " + (loaded ? "loaded" : "")}>
        <img src={Logo} />
      </div>
      {loaded && (
        <main>
          <Header />
          <Hero />
          <Documents />
          <Footer />
        </main>
      )}
    </>
  );
};

export default App;
