import React, { useState } from "react";
import styles from "./styles.module.scss";
import { Modal } from 'antd';
import Container from "../ui/container/Container";
import Form from "../form/Form";
import {shallowEqual, useSelector} from "react-redux";

const Hero = () => {
  const { prices, additionalPrice } = useSelector((store) => store.app, shallowEqual);

  const [showDetails, setShowDetails] = useState(false);

  return (
    <div className={styles.hero}>
      <Container className={styles.container}>
        <div className={styles.content}>
          <h1>Техосмотр</h1>
          <ul>
            <li>
              Технический осмотр
              {!!prices.A && <div>Мотоцикл - <b>{prices.A} рублей</b></div>}
              {!!prices.B && <div>Легковой автомобиль - <b>{prices.B} рублей</b></div>}
              {!!prices.C && <div>Грузовой автомобиль - <b>{prices.C} рублей</b></div>}
              {!!prices.D && <div>Автобус - <b>{prices.D} рублей</b></div>}
              {!!prices.O && <div>Прицеп - <b>{prices.O} рублей</b></div>}
            </li>
            <li>Повторное прохождение осмотра - {prices.secondCheck} рублей</li>
            <li>
              <a href="#" onClick={() => setShowDetails(true)} >Подробнее о стоимости услуг</a>
            </li>
          </ul>
        </div>
        <div className={styles.form}>
          <Form />
        </div>
      </Container>

      <Modal
          visible={showDetails}
          onCancel={() => setShowDetails(false)}
          footer={null}
      >
          <pre>{additionalPrice}</pre>
      </Modal>
    </div>
  );
};

export default Hero;
