import React, { useState } from "react";
import styles from "./styles.module.scss";
import Container from "../ui/container/Container";
import { ReactComponent as DocumentIcon } from "./document-icon.svg";
import picture from "./picture.jpg";
import { Modal } from "antd";
import { shallowEqual, useSelector } from "react-redux";

const Documents = () => {
  const [showCertificate, setShowCertificate] = useState(false);
  const { documents: pdf, requisites } = useSelector((store) => store.app, shallowEqual);


  const documents = [
    "Паспорт гражданина РФ владельца ТС",
    "СТС, ПТС или Техпаспорт",
    "Водительское удостоверение",
    "Доверенность (для представителя владельца ТС)",
  ];

  const ourDocuments = [
    {
      title: "Аттестат аккредитации",
      onClick:  () => window.open(requisites.certificate, "_blank"),

    },
    {
      title:
        'Федеральный закон "О техническом осмотре транспортных средств и о внесении изменений в отдельные законодательные акты Российской Федерации"',
      onClick: () => {
        window.open(pdf.techLaw, "_blank");
      },
    },
    {
      title: "Правила проведения технического осмотра ",
      onClick: () => {
        window.open(pdf.rules, "_blank");
      },
    },
    {
      title: "Типовая форма договора о проведении технического осмотра",
      onClick: () => {
          window.open(pdf.typicalAgreement, "_blank");
      },
    },
  ];

  return (
    <div className={styles.documents}>
      <Container>
        <h2>Какие нужны документы?</h2>

        <div className={styles.grid}>
          {documents.map((name, i) => (
            <div className={styles.document} key={i}>
              <DocumentIcon />
              {name}
            </div>
          ))}
        </div>

        <h2>Наши документы</h2>

        <div className={styles.ourDocuments}>
          <div className={styles.list}>
            {ourDocuments.map((document, i) => (
              <div
                className={styles.document}
                key={i}
                onClick={document.onClick}
              >
                <DocumentIcon />
                {document.title}
              </div>
            ))}
          </div>

          <img src={picture} alt="Станция ПТО" className={styles.picture} />
        </div>
      </Container>

      <Modal
        visible={showCertificate}
        onCancel={() => setShowCertificate(false)}
        width={1000}
        footer={null}
      >
          <img src={requisites.certificate} alt={requisites.name} className={styles.certificate}/>
      </Modal>
    </div>
  );
};

export default Documents;
