import React from "react";
import Container from "../ui/container/Container";
import styles from "./styles.module.scss";
import { ReactComponent as Logo } from "./logo.svg";
import { ReactComponent as PlaceIcon } from "./place-icon.svg";
import { ReactComponent as PhoneIcon } from "./phone-icon.svg";
import {shallowEqual, useSelector} from "react-redux";

const Header = () => {
  const { requisites } = useSelector((store) => store.app, shallowEqual);

  return (
    <div className={styles.header}>
      <Container className={styles.container}>
        <div className={styles.logo}>
          <Logo />
          <div className={styles.name}>{requisites.shortName}</div>
        </div>

        <div className={styles.address}>
          <PlaceIcon />
          {requisites.addressStation}
        </div>

        <div className={styles.address}>
          <PhoneIcon />
          {requisites.phone}
        </div>
      </Container>
    </div>
  );
};

export default Header;
