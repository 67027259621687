import axios from 'axios';
const endpoint = 'https://api.avtospeya.ru/';

const Api = (url, params = {}, method = 'get', headers = {}) => {

  return axios({
    url,
    method,
    params: method === 'get' ? params : null,
    data: method !== 'get' ? params : null,
    baseURL: endpoint,
    headers,
  });
};

export default Api;
